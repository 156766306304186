//import jwtDecode from "jwt-decode";

// const initialState = {
//     best_selling_products: [],
//     menu: [],
//     new_products: [],
//     sections: [],
//     special_offer_products: [],
//     sliders: []
// }
export const ui = (state = {}, action) => {
    switch (action.type) {
        case "UI": {
            return {
                menu: action.payload.menu,
                articles: action.payload.articles,
                courses: action.payload.courses,
                category_parent: action.payload.categories,
                quick_news: action.payload.slider_text,
                sliders: action.payload.sliders,
                sections: action.payload.sections,
                background: action.payload.background,
                story: action.payload.story
            }
        }
        default: {
            return state
        }
    }
}
